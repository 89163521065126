/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    br: "br",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, SideBySide, TwoColCta, MapInfo, QuestionAccordion, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com (partners of ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Signia"), ") is one of the most largest hearing aid providers in the world. Utilizing a revolutionary digital approach to inspire people to act on their hearing loss, this German company consistently wows the world with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "top-of-the-line technology"), ", ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "high-end hearing aid devices"), ", and a commitment to quality and precision. At hear.com, they believe you need to hear well to live well.  Their line-up of German hearing aids are designed to deliver the most natural listening experience, no matter where you are."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " want the best for you (our customer!), which is why we’ve selected Signia’s hearing aids to help you reach your hearing goals in record time. Learn more about our devices, powered by the revolutionary new Integrated Xperience (IX) platform, right here:"), "\n", React.createElement(LandingPageCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-integrated-xperience-the-latest-in-hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-integrated-xperience-the-latest-in-hearing-aid-technology",
    "aria-label": "signia integrated xperience the latest in hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Integrated Xperience: The Latest in Hearing Aid Technology"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-x.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to the company’s never-ending dedication to innovation, they’re constantly producing devices that reshape the industry — complete with benefits that go beyond anything previously thought possible. Built off the foundations of Siemens’ world-famous audiological devices, the IX platform prides itself on its list of high-end features, tailored specifically for the wearer.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The guiding principles of the Integrated Xperience platform are to “replicate nature,” in removing all the ugly artificial sounds in your ears by your old hearing aids and replacing them with crystal-clear, natural listening experiences you know and love. Here are some of the top features of the IX platform:")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "own-voice-processing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#own-voice-processing",
    "aria-label": "own voice processing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Own Voice Processing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia’s proprietary ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/own-voice-processing/",
    className: "c-md-a"
  }, "“own voice processing”"), " system helps eliminate the unwanted distortion of the wearer’s own voice for a better listening experience. Using Ultra HDe2e technology, these German hearing aids focus on the wearer’s voice to deliver a sound that imitates the natural noise – even in loud environments."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "immaculate-clarity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#immaculate-clarity",
    "aria-label": "immaculate clarity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Immaculate clarity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Using that same Ultra HDe2e technology that helps Signia’s hearing aids identify the wearer’s voice, the Integrated Xperience platform is an industry leader at picking up on other people’s voices to help improve speech processing in real-time. By allowing you to zero in on the speech of specific individuals, the Horizon hearing aids make it easier to keep up with conversations, wherever life might take you."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "stream-directly-from-your-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stream-directly-from-your-devices",
    "aria-label": "stream directly from your devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stream directly from your devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to the Horizon’s Bluetooth streaming capabilities, with many of these German hearing aids, you can connect directly to your favorite devices for a seamless listening experience. Whether you want to connect to your phone to chat away with friends or to your TV to better hear your shows, Horizon’s Bluetooth-enabled platform is there for you."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "take-control-with-the-mycontrol-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-control-with-the-mycontrol-app",
    "aria-label": "take control with the mycontrol app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take control with the MyControl app"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t have to wait for your next appointment to adjust your hearing aids. With Signia’s MyControl app, you don’t have to. Thanks to this app, you can control the settings of your hearing aids at any time, to fine-tune your devices to best meet the needs of your environment. What’s not to love?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "german-hearing-aids-selected-by-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#german-hearing-aids-selected-by-hearcom",
    "aria-label": "german hearing aids selected by hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "German Hearing Aids Selected by hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The innovators over at Signia know that hearing aids aren’t a one-size-fits-all solution, which is why they have a whole line of offerings built on the Integrated Xperience platform to meet each of your unique needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com features several hearing aids for all levels of hearing loss. They have recently partnered with Signia, using aspects of their technology platform to create their own line of ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "unique hearing aids called Horizon"), ". hear.com's Horizon hearing aids have taken hearing ability to a new level and are designed to meet the needs of people who won’t take anything less than the best as an answer for their hearing challenges."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix",
    className: "c-md-a"
  }, "Horizon Mini IX (in-the-ear)"), " and the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix",
    className: "c-md-a"
  }, "Horizon Go IX (behind-the-ear)"), " hearing aids by hear.com are so tiny that they’re virtually invisible, allowing you to go about life without anything holding you back. These two hearing aids are both built upon the Xperience platform, so you can be confident and certain in your hearing abilities in any environment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Go IX is equipped with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), ", allowing you to connect to Bluetooth-enabled devices to stream audio right into your hearing aids. The Horizon Mini IX is one of the smallest hearing aids – with the best speech clarity – in the world. And, thanks to their ability to pick up sound in the noisiest of environments, you’ll never miss any of life’s important moments again."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best part? Signia’s Xperience technology is incredibly advanced but is surprisingly affordable. There’s a German hearing aid out there for you and it’s available at hear.com – we are ready to help you find the one that fits your lifestyle and budget."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "looking-for-your-next-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#looking-for-your-next-hearing-aids",
    "aria-label": "looking for your next hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Looking for Your Next Hearing Aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While we here at hear.com love German hearing aids for their long history of quality and precision, it’s important that you choose a hearing aid based on the specific needs of your hearing loss and lifestyle. Whatever you might be looking for, hear.com is here to help you, with 2,000 locations across the United States. Sign up now to achieve your potential!"), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Check if you Qualify",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequently-asked-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequently-asked-questions",
    "aria-label": "frequently asked questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequently asked questions"), "\n", React.createElement(QuestionAccordion, {
    question: "What is the cost of german hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The cost of German hearing aids can vary widely based on several factors, including the brand, model, features, and the provider or retailer. German hearing aids are often associated with high quality and advanced technology, which can influence their pricing. Entry-level German hearing aids may start around a few hundred dollars, while more advanced models with cutting-edge features can range from several hundred to several thousand dollars per hearing aid."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "german hearing aids cost"), ".")), "\n", React.createElement(QuestionAccordion, {
    question: "Are there german hearing aids with rechargeable battery options?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Engineered for active people who desire world-class hearing that lasts all day, the Horizon Go IX german hearing aids by hear.com rises to the top of its class with an extended battery life that only stops when you do. With seamless connectivity and no tiny batteries to change, staying connected while on the go has never been so easy."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX hearing aids"), ".")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
